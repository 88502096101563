<template>
    <b-card no-body>
        <v-table    ref="table"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { IrsaliyeOperationsApi } from '@/api/irsaliye'
export default {
    components:{
        BCard,
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Irsaliye Grup Kodu',key:'ReferenceNo',sortable:true, tdClass:'w-15',thClass:'text-nowrap'},
                {name:'Taşıyıcı Cari Kod',key:'CarrierCariCode',sortable:true, tdClass:'w-15',thClass:'text-nowrap'},
                {name:'Taşıyıcı Cari Adı',key:'CarrierCariName',sortable:true, tdClass:'w-20',thClass:'text-nowrap',tdClass:'text-nowrap'},
                {name:'Başlangıç Tarihi',key:'StartDate',sortable:true, tdClass:'w-7',thClass:'text-nowrap'},
                {name:'Bitiş Tarihi',key:'EndDate',sortable:true, tdClass:'w-8',thClass:'text-nowrap'},
                {name:'Durum',key:'StatusStr',sortable:true, tdClass:'w-35',thClass:'text-nowrap',tdClass:'text-nowrap'},
            ],
            buttons:[
                { text:"Düzenle ve Gönder",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit,condition:(item) =>item.Status != 11 },
                { text:"Detay",key:"edit",icon:"EyeIcon",variant:"success",action:this.edit,condition:(item) =>item.Status == 11  },
                { text:"Sil",key:"edit",icon:"TrashIcon",variant:"danger",action:this.delete,condition:(item) => item.Status != 11 },
                { text:"Süreci Tamamla",key:"complete",icon:"CheckIcon",variant:"success",action:this.complete, condition:(item)=>item.Status ==4 || item.Status == 2 },
            ]
        }
    },
    methods:{
        complete(item){
            IrsaliyeOperationsApi.Complete(item.Id).then(res => {
                this.$result(res)
                this.$refs.table.refresh()
            })
        },
        provider(data){
            return IrsaliyeOperationsApi.Table(data)
        },
        createNew(){
            this.clearForm()
            this.$showModal('create-or-update')
        },
        edit(item){
            this.$router.push({name:'waybill-detail',params:{id:item.Id}})
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.ReferenceNo} adlı irsaliye grubunu silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>